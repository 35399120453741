import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ApiService } from '../../services/api.service'
import { DataService } from './data.service'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
    contents: any = ''
    lang: any
    loadingStatus = false
    constructor(
        private ds: DataService,
        private api: ApiService,
        private sanitizer: DomSanitizer,
        private ar: ActivatedRoute
    ) {
        ar.paramMap.subscribe( (param: any) => {
            this.loadContent(param.get('route'))
        })
    }

    ngOnInit() {
        this.loadingStatus = true
        this.lang = this.api.translate('website.pages')
        this.lang.subscribe((d: any) => {
            this.lang = d
        })
    }

    loadContent(route: string) {
        this.loadingStatus = false
        this.ds.getContent(route).subscribe((resp: any) => {
            this.loadingStatus = false
            if (resp.success === true && resp.data !== null) {
                this.contents = resp.data[`content${this.api.getCurrentLang()}`]
                this.contents = resp.data.contentEn
            } else {
                this.contents = '<h1>Page Not Found!!</h1>'
            }
        })
    }

    transformHtml(htmlTextWithStyle: any): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle)
    }

}
